import styled from "styled-components"
import { TextOverText } from "../../common/TextOverText"
import {
  BigText,
  SmallText,
} from "../../common/TextOverText/TextOverText.style"

export const Wrapper = styled.div`
  width: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
`

export const Title = styled(TextOverText)`
  ${BigText} {
    font-family: ${({ theme }) => theme.typography.body.fontFamily};
    font-weight: ${({ theme }) => theme.typography.h2.fontWeight};
    font-size: 3em;
    opacity: 1;
    padding-top: 5%;
    line-height: 3rem;
    &.mobile {
      padding-top: 10%;
      font-size: 25px;
    }
  }
  ${SmallText} {
    line-height: 1rem;
    position: absolute;
  }
`

export const Presentation = styled.div`
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: 1.5em;
  padding-left: 5%;
  padding-bottom: 2%;
  &.mobile {
    font-size: 1em;
  }
`

export const Contacts = styled.div`
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  font-size: 1.25em;
  margin-left: 10%;
  list-style-type: circle;
  align-items: center;
  margin-right: 10%;
  padding-left: 1.5em;
  background-color: ${({ theme }) => theme.palette.primary.light};
  &.mobile {
    font-size: 0.9em;
  }
`

export const Adresses = styled.div`
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-weight: bold;
  font-size: 1.15em;
  padding-bottom: 5%;
  padding-left: 4%;
  padding-top: 5%;
  background-color: lightgrey;
  &.mobile {
    font-size: 0.95em;
  }
`

export const Infos = styled.div`
  padding-top: 50px;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: 1em;
  padding-left: 5%;
  padding-bottom : 2em;
`
