import React from "react"

import { PageContactView } from "./PageContact.view"

interface Props {
  className?: string
}

export function PageContactContainer({ className }: Props) {
  return <PageContactView className={className} />
}
